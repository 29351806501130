import Vue from 'vue'

const state = {
  cost_tpe: [],
  job_tpe: [],
  user: [],
  status: [],
  setting: [],
  tpl: [],
  service: [],
  storage: [],
  goodGroup: [],
  provider: [],
  adr: [],
  account: [],
  docprint: [],
  communication: [],
}

// mutations
const mutations = {
  clear(state) {
    state.job_tpe = []
    state.user = []
    state.status = []
    state.setting = []
    state.tpl = []
    state.service = []
    state.storage = []
    state.goodGroup = []
    state.provider = []
    state.adr = []
    state.account = []
    state.docprint = []
    state.communication = []
  },
}
const getters = {
  getJobTpe(state) {
    if (state.job_tpe.length == 0) {
      //        console.log(this);
      //        console.log(state);
    }
    return state.job_tpe
  },
}
const actions = {
  loadJobTpe(store) {
    return new Promise((resolve) => {
      if (state.job_tpe.length == 0) {
        Vue.http.post(store.rootState.apiUrl + 'job/tpe/').then(
          (response) => {
            store.state.job_tpe = response.data
            resolve()
          },
          (err) => {
            console.log(err)
            resolve()
          }
        )
      } else {
        resolve()
      }
    })
  },
  loadAccount(store) {
    return new Promise((resolve) => {
      if (state.account.length == 0) {
        Vue.http.post(store.rootState.apiUrl + 'spr/account/').then(
          (response) => {
            store.state.account = response.data
            resolve()
          },
          (err) => {
            console.log(err)
            resolve()
          }
        )
      } else {
        resolve()
      }
    })
  },
  loadUser(store) {
    if (state.user.length == 0) {
      Vue.http.post(store.rootState.apiUrl + 'user/').then(
        (response) => {
          store.state.user = response.data
        },
        (err) => {
          console.log(err)
        }
      )
    }
  },
  loadCostTpe(store) {
    return new Promise((resolve) => {
      if (state.cost_tpe.length == 0) {
        Vue.http.post(store.rootState.apiUrl + 'spr/costtpe').then(
          (response) => {
            store.state.cost_tpe = response.data
            resolve()
          },
          (err) => {
            console.log(err)
            resolve()
          }
        )
      } else {
        resolve()
      }
    })
  },
  loadStatus(store) {
    return new Promise((resolve) => {
      if (state.status.length == 0) {
        Vue.http.post(store.rootState.apiUrl + 'status').then(
          (response) => {
            store.state.status = response.data
          },
          (err) => {
            console.log(err)
          }
        )
      } else {
        resolve()
      }
    })
  },
  loadSetting(store) {
    if (state.setting.length == 0) {
      Vue.http.post(store.rootState.apiUrl + 'setting').then(
        (response) => {
          store.state.setting = response.data.setting
        },
        (err) => {
          console.log(err)
        }
      )
    }
  },
  loadTpl(store) {
    if (state.tpl.length == 0) {
      Vue.http.post(store.rootState.apiUrl + 'spr/tpl').then(
        (response) => {
          store.state.tpl = response.data
        },
        (err) => {
          console.log(err)
        }
      )
    }
  },
  loadService(store) {
    if (state.service.length == 0) {
      Vue.http.post(store.rootState.apiUrl + 'price/service').then(
        (response) => {
          store.state.service = response.data
        },
        (err) => {
          console.log(err)
        }
      )
    }
  },
  updateService(store) {
    state.service = []
    store.dispatch('loadService')
  },
  loadStorage(store) {
    return new Promise((resolve) => {
      if (state.storage.length == 0) {
        Vue.http.post(store.rootState.apiUrl + 'good/storage').then(
          (response) => {
            store.state.storage = response.data
            resolve()
          },
          (err) => {
            console.log(err)
            resolve()
          }
        )
      } else {
        resolve()
      }
    })
  },
  loadDocprint(store) {
    return new Promise((resolve) => {
      if (state.docprint.length == 0) {
        Vue.http.post(store.rootState.apiUrl + 'docprint').then(
          (response) => {
            store.state.docprint = response.data
            resolve()
          },
          (err) => {
            console.log(err)
            resolve()
          }
        )
      } else {
        resolve()
      }
    })
  },
  updateStorage(store) {
    state.storage = []
    store.dispatch('loadStorage')
  },
  updateSetting(store) {
    state.setting = []
    store.dispatch('loadSetting')
  },
  loadGoodGroup(store) {
    return new Promise((resolve) => {
      if (state.goodGroup.length == 0) {
        Vue.http.post(store.rootState.apiUrl + 'good/priceGroup').then(
          (response) => {
            store.state.goodGroup = response.data
            resolve()
          },
          (err) => {
            console.log(err)
            resolve()
          }
        )
      } else {
        resolve()
      }
    })
  },
  updateGoodGroup(store) {
    state.goodGroup = []
    store.dispatch('loadGoodGroup')
  },
  loadProvider(store) {
    return new Promise((resolve) => {
      if (state.provider.length == 0) {
        Vue.http.post(store.rootState.apiUrl + 'good/provider').then(
          (response) => {
            store.state.provider = response.data
            resolve()
          },
          (err) => {
            console.log(err)
            resolve()
          }
        )
      } else {
        resolve()
      }
    })
  },
  updateProvider(store) {
    state.provider = []
    store.dispatch('loadProvider')
  },
  loadAdr(store) {
    return new Promise((resolve) => {
      if (state.provider.length == 0) {
        Vue.http.post(store.rootState.apiUrl + 'adr/').then(
          (response) => {
            store.state.adr = response.data
            resolve()
          },
          (err) => {
            console.log(err)
            resolve()
          }
        )
      } else {
        resolve()
      }
    })
  },
  updateAdr(store) {
    state.adr = []
    store.dispatch('loadAdr')
  },
  loadCommunication(store) {
    return new Promise((resolve) => {
      if (state.provider.length == 0) {
        Vue.http.post(store.rootState.apiUrl + 'spr/communication').then(
          (response) => {
            store.state.communication = response.data
            resolve()
          },
          (err) => {
            console.log(err)
            resolve()
          }
        )
      } else {
        resolve()
      }
    })
  },
}
//const computed = {
//  getToken () {
//    return this.$store.state.token
//  }
//}

export default {
  state,
  mutations,
  getters,
  actions,
}
