import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth.js'
import spr from './modules/spr'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    apiUrl: 'https://api.usefulmaster.ru/',
    // apiUrl: 'http://um_api.ce-saas.ru/',
    //        apiUrl:'',
    message: '',
    messageType: '',
    count: 0,
    loadingStart: false,
  },
  actions: {
    setMessage(context, prm) {
      context.commit('setMessage', prm)
      setTimeout(() => {
        context.commit('setMessage', { message: '' })
      }, 3000)
    },
  },
  mutations: {
    setUrl(state, prm) {
      state.apiUrl = prm.url
    },
    setMessage(state, prm) {
      state.message = prm.message
      state.messageType = prm.type
    },
    loadingStartShow(state, flg) {
      state.loadingStart = flg
    },
  },
  modules: {
    auth,
    spr,
  },
})
