<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>

          <v-spacer></v-spacer>
          <span v-if="smena.id > 0">
            <b>День №{{ smena.num }}</b>
          </span>
          <span v-else-if="smena.num == 'close'">
            <v-icon left> fa-times </v-icon>
            День закрыт
          </span>
          <span v-else> </span>
          <v-spacer></v-spacer>
          <!-- Right Content -->

          <theme-switcher></theme-switcher>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container pa-6 mx-auto h-full">
        <slot></slot>
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0">
      <div class="boxed-container w-full ">
        <div class="mx-6 d-flex justify-space-between">
          <span>
            &copy; 2022
            <a href="https://beauty-saas.ru/" class="text-decoration-none" target="_blank">UsefullMaster</a></span
          >
          <span class="d-sm-inline d-none">
            <!--a
              href="https://../"
              target="_blank"
              class="me-6 text--secondary text-decoration-none"
            >Freebies</!--a-->
          </span>
        </div>
      </div>
    </v-footer>
    <v-snackbar :timeout="-1" :value="true" app top outlined v-show="message" :color="messageCls">
      {{ message }}
    </v-snackbar>
    <v-dialog v-model="loadData" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Загрузка данных ...
          <v-progress-linear indeterminate rounded color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <dialogs-wrapper></dialogs-wrapper>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },
  computed: {
    loadData: function() {
      return this.$store.state.loadingStart
    },
    message: function() {
      return this.$store.state.message
    },
    messageCls: function() {
      if (this.$store.state.messageType == 'error') return 'error'
    },
    username: function() {
      return this.$store.state.username
    },
    smena: function() {
      return this.$store.state.auth.smena
    },
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
