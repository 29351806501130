<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-slide-x-transition>
          <v-img
            v-if="org == 88"
            :src="require('@/assets/logo.png')"
            max-height="50px"
            max-width="200px"
            contain
            eager
            class="app-logo me-3"
          ></v-img>
          <v-img
            v-if="org == 153"
            :src="require('@/assets/logo153.png')"
            max-height="50px"
            max-width="200px"
            contain
            eager
            class="app-logo me-3"
          ></v-img>
          <v-img
            v-else
            :src="require('@/assets/umlogo.svg')"
            max-height="50px"
            max-width="200px"
            contain
            eager
            class="app-logo me-3"
          ></v-img>
          <h2 class="app-title text--primary">
            Usefulmaster
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->

    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link title="Главная" :to="{ name: 'main' }" icon="fa-home"></nav-menu-link>
      <nav-menu-link
        v-if="adrs.length > 1"
        title="Сменить адрес"
        :to="{ name: 'adr' }"
        icon="fa-refresh"
      ></nav-menu-link>
    </v-list>
    <v-list expand shaped class="vertical-nav-menu-items pr-5" v-for="(item, idx) in menus" v-bind:key="idx">
      <nav-menu-link :title="item.name" :to="{ name: item.link }" :icon="item.icon"></nav-menu-link>
    </v-list>
    <center>ID:{{ org }}</center>
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link title="Выход" :to="{ name: 'login' }" icon="fa-sign-out"></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      adrs: [],
    }
  },
  computed: {
    org: function() {
      return this.$store.state.auth.org
    },
    menus: function() {
      var r = this.$store.state.auth.resource,
        menu = [],
        t = this
      console.log('menu', r)
      if (this.$store.state.auth.resource.indexOf('job.view') >= 0) {
        menu.push({
          name: t.$t('menu.job'),
          icon: 'fa-wrench',
          link: 'job',
        })
      }
      if (this.$store.state.auth.resource.indexOf('cashbox.view') >= 0) {
        menu.push({
          name: t.$t('menu.finance'),
          icon: 'fa-credit-card',
          link: 'smena',
        })
      }
      if (this.$store.state.auth.resource.indexOf('good.view') >= 0) {
        menu.push({
          name: t.$t('menu.storage'),
          icon: 'fa-barcode',
          link: 'storage',
        })
      }
      if (this.$store.state.auth.resource.indexOf('customer.view') >= 0) {
        menu.push({
          name: t.$t('menu.customer'),
          icon: 'fa-user',
          link: 'customer',
        })
      }
      if (this.$store.state.auth.resource.indexOf('certificate.view') >= 0) {
        menu.push({
          name: t.$t('menu.certificate'),
          icon: 'fa-user',
          link: 'certificate',
        })
      }
      if (this.$store.state.auth.resource.indexOf('report.view') >= 0) {
        menu.push({
          name: t.$t('menu.report'),
          icon: 'fa-bar-chart',
          link: 'report',
        })
      }

      if (this.$store.state.auth.resource.indexOf('setting.view') >= 0) {
        menu.push({
          name: t.$t('menu.settings'),
          icon: 'fa-cog',
          link: 'msetting',
        })
      }
      if (this.$store.state.auth.resource.indexOf('billing.view') >= 0) {
        menu.push({
          name: t.$t('menu.billing'),
          icon: 'fa-shopping-cart',
          link: 'billing',
        })
      }
      if (this.$store.state.auth.resource.indexOf('billing.view') >= 0) {
        menu.push({
          name: t.$t('menu.profile'),
          icon: 'fa-user',
          link: 'profile',
        })
      }
      return menu
    },
  },
  mounted: function() {
    this.listAdr()
  },
  setup() {
    return {}
  },
  methods: {
    exit: function() {
      console.log(123)
      var t = this
      this.$store.dispatch('mLogin', {}).then(() => {
        t.$router.push('login')
      })
    },
    listAdr: function() {
      // var t = this
      // t.$store.commit('loadData', true)
      // this.$http
      //   .post(this.$store.state.apiUrl + t.org + '/worker/GetAdr', {
      //     token: t.token,
      //     user: t.user,
      //   })
      //   .then(
      //     response => {
      //       t.$store.commit('loadData', false)
      //       if (response.body.err > 0) {
      //         t.adrs = ['error']
      //       } else {
      //         t.adrs = response.body
      //       }
      //     },
      //     err => {
      //       console.log(err)
      //     },
      //   )
    },
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
