import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'main',
  },
  {
    path: '/main',
    name: 'main',
    component: () => import('@/views/smena/smena.vue'),
  },
  {
    path: '/rule',
    name: 'rule',
    component: () => import('@/views/rule/rule.vue'),
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user/user.vue'),
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/user/profile.vue'),
  },
  {
    path: '/status',
    name: 'status',
    component: () => import('@/views/status/status.vue'),
  },
  {
    path: '/customer',
    name: 'customer',
    component: () => import('@/views/customer/customer.vue'),
  },
  {
    path: '/customer_group',
    name: 'customer_group',
    component: () => import('@/views/customer/customer_group.vue'),
  },
  {
    path: '/recommendation',
    name: 'recommendation',
    component: () => import('@/views/recommendation/recommendation.vue'),
  },
  {
    path: '/job',
    name: 'job',
    component: () => import('@/views/job/job.vue'),
  },
  {
    path: '/item',
    name: 'item',
    component: () => import('@/views/item/item.vue'),
  },
  {
    path: '/cashbox',
    name: 'cashbox',
    component: () => import('@/views/cashbox/cashbox.vue'),
  },
  {
    path: '/smena',
    name: 'smena',
    component: () => import('@/views/smena/smena.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/reg',
    name: 'reg',
    component: () => import('@/views/login/reg.vue'),
  },
  {
    path: '/msetting',
    name: 'msetting',
    component: () => import('@/views/setting/msetting.vue'),
  },
  {
    path: '/setting',
    name: 'setting',
    component: () => import('@/views/setting/setting.vue'),
  },
  {
    path: '/notification',
    name: 'notification',
    component: () => import('@/views/notification/notification.vue'),
  },
  {
    path: '/billing',
    name: 'billing',
    component: () => import('@/views/billing/billing.vue'),
  },
  {
    path: '/reportcash',
    name: 'reportcash',
    component: () => import('@/views/report/reportcash.vue'),
  },
  {
    path: '/price',
    name: 'price',
    component: () => import('@/views/price/price.vue'),
  },
  {
    path: '/storage',
    name: 'storage',
    component: () => import('@/views/storage/index.vue'),
  },
  {
    path: '/reportgoodtrash',
    name: 'reportgoodtrash',
    component: () => import('@/views/report/goodtrash.vue'),
  },
  {
    path: '/print',
    name: 'print',
    component: () => import('@/views/print/print.vue'),
  },
  {
    path: '/salary',
    name: 'salary',
    component: () => import('@/views/salary/salary.vue'),
  },
  {
    path: '/report_salary',
    name: 'report_salary',
    component: () => import('@/views/report/report_salary.vue'),
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/report/report.vue'),
  },
  {
    path: '/report_service',
    name: 'report_service',
    component: () => import('@/views/report/service.vue'),
  },
  {
    path: '/report_worker',
    name: 'report_worker',
    component: () => import('@/views/report/worker.vue'),
  },
  {
    path: '/report_statistic',
    name: 'report_statistic',
    component: () => import('@/views/report/stat.vue'),
  },
  {
    path: '/report_smena',
    name: 'report_smena',
    component: () => import('@/views/report/smena.vue'),
  },
  {
    path: '/jobfield',
    name: 'jobfield',
    component: () => import('@/views/jobfield/jobfield.vue'),
  },
  {
    path: '/customerfield',
    name: 'customerfield',
    component: () => import('@/views/customerfield/customerfield.vue'),
  },

  {
    path: '/adr',
    name: 'adr',
    component: () => import('@/views/adr/adr.vue'),
  },
  {
    path: '/certificate',
    name: 'certificate',
    component: () => import('@/views/certificate/certificate.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  // {
  //   path: '*',
  //   redirect: 'error-404',
  // },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (
    (!router.app.$store.state.auth.token || !router.app.$store.state.auth.login || !router.app.$store.state.auth.adr) &&
    to.path != '/login' &&
    to.path != '/reg'
  ) {
    //        console.log(this)
    //        console.log(Vue)
    // может быть есть в localstorage?
    //        console.log(localStorage.getItem('AuthLogin'),localStorage.getItem('AuthToken'),localStorage.getItem('Adr'));
    // console.log(111);
    if (localStorage.getItem('AuthLogin') && localStorage.getItem('AuthToken') && localStorage.getItem('Adr')) {
      // проверяем на валидность

      router.app.$http
        .post(router.app.$store.state.apiUrl + 'login/CheckToken/?token=' + localStorage.getItem('AuthToken'), {
          token: localStorage.getItem('AuthToken'),
        })
        .then(
          response => {
            if (response.body.err > 0) {
              // устарел токен, надо удалить
              localStorage.removeItem('AuthLogin')
              localStorage.removeItem('AuthToken')
              localStorage.removeItem('Adr')
              localStorage.removeItem('Org')
              localStorage.removeItem('AdrName')
              localStorage.removeItem('Tpl')
              localStorage.removeItem('User_fio')
              localStorage.removeItem('Resource')
              router.app.$store.commit('clear')
              next({ path: '/login', query: { redirect: to.fullPath } })
            } else {
              // токен есть в базе, востанавливаем сессию
              // if (
              //   document.location.pathname == '/:admin' &&
              //   document.location.search == '?org=3'
              // ) {
              //   localStorage.setItem('Org', 3)
              // }
              router.app.$store.commit('mLogin', {
                login: localStorage.getItem('AuthLogin'),
                token: localStorage.getItem('AuthToken'),
                adr: localStorage.getItem('Adr'),
                org: localStorage.getItem('Org'),
                adr_name: localStorage.getItem('AdrName'),
                tpl: localStorage.getItem('Tpl'),
                user_fio: localStorage.getItem('User_fio'),
                resource: localStorage.getItem('Resource').split(','),
              })
              router.app.$store.dispatch('loadAdr')
              router.app.$store.dispatch('loadSmena')
              next()
            }
          },
          err => {
            console.log(err)
          },
        )

      return
    }
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else {
    next()
  }
})

export default router
