import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import Resource from 'vue-resource'
import Vueditor from 'vueditor'
import * as ModalDialogs from 'vue-modal-dialogs'
import store from './store'
import router from './router'
import i18n from './i18n.js'
import axios from 'axios'
Vue.use(axios)
// Vue.use(i18n)

import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.use(Resource)

import 'font-awesome/css/font-awesome.min.css'
import 'vueditor/dist/style/vueditor.min.css'

const config = {
  toolbar: [
    'removeFormat',
    'undo',
    '|',
    'elements',
    'fontName',
    'fontSize',
    'foreColor',
    'backColor',
    'divider',
    'bold',
    'italic',
    'underline',
    'strikeThrough',
    'links',
    'divider',
    'subscript',
    'superscript',
    'divider',
    'justifyLeft',
    'justifyCenter',
    'justifyRight',
    'justifyFull',
    '|',
    'indent',
    'outdent',
    'insertOrderedList',
    'insertUnorderedList',
    '|',
    'picture',
    'table',
    '|',
    'sourceCode',
    'markdown',
  ],
  fontName: [{ val: 'arial black' }, { val: 'times new roman' }, { val: 'Courier New' }],
  fontSize: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem'],
  uploadUrl: '',
}
Vue.use(Vueditor, config)
Vue.use(ModalDialogs)
Vue.config.productionTip = false

Vue.http.options.emulateJSON = true
// axios.defaults.headers.post['Content-Type'] = 'application/json'
// console.log('🚀 ~ file: main.js ~ line 67 ~ axios.axios.defaults.responseType', axios.defaults.responseType)
// axios.defaults.responseType = 'json'
// responseType: 'json'
// axios.defaults.headers.post['adr'] = prm.responseType
Vue.http.interceptors.push(function(request, next) {
  request.method = 'POST'
  request.headers.set('token', store.state.auth.token)
  request.headers.set('login', store.state.auth.login)
  request.headers.set('adr', store.state.auth.adr)
  next()
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
