import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
// При изменении языка переключаем файл /locals/_language_.json
function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

function checkDefaultLanguage() {
  //Определяем язык браузера
  let matched = null
  let languages = Object.getOwnPropertyNames(loadLocaleMessages())

  languages.forEach(lang => {
    if (lang === navigator.language) {
      matched = lang
    }
  })
  if (!matched) {
    languages.forEach(lang => {
      let languagePartials = navigator.language.split('-')[0]
      if (lang === languagePartials) {
        matched = lang
      }
    })
  }
  return matched
}
export const selectedLocale = localStorage.getItem('locale') || checkDefaultLanguage() || 'en'
console.log('🚀 ~ file: i18n.js ~ line 45 ~ checkDefaultLanguage()', checkDefaultLanguage())
console.log('🚀 ~ file: i18n.js ~ line 43 ~ selectedLocale', selectedLocale)
export const languages = Object.getOwnPropertyNames(loadLocaleMessages())
export default new VueI18n({
  locale: selectedLocale || 'ru',
  globalInjection: true,
  fallbackLocale: 'ru',
  messages: loadLocaleMessages(),
})

// Определяем предустановленный язык браузера и устанавливаем его при запуске
// function detectLanguage() {
//   const lng = window.navigator.userLanguage || window.navigator.language;
//   const locales = require.context(
//     "./locales",
//     true,
//     /[A-Za-z0-9-_,\s]+\.json$/i
//   );
//   const lang = locales
//     .keys()
//     .find((key) => lng.includes(key.replace("./", "").replace(".json", "")));
//     console.log('detectLanguage',lang ? lang.replace("./", "").replace(".json", ""): null);

//   return lang ? lang.replace("./", "").replace(".json", "") : null;
// }
// //const locale = 'ru-RU'; // default locale
// export default new VueI18n({
//   locale:
//     localStorage.getItem("lang") ||
//     detectLanguage() ||
//     process.env.VUE_APP_I18N_LOCALE,
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ru",
//   messages: loadLocaleMessages(),
// });
