import Vue from 'vue'
import axios from 'axios'

const state = {
  login: null,
  user_fio: null,
  pass: null,
  token: null,
  adr: null,
  org: null,
  adr_name: '',
  tpl: 1,
  resource: [],
  smena: { id: '', num: '' },
}

// mutations
const mutations = {
  mLogin(state, prm) {
    // console.log(prm)
    // console.log('123')
    if (prm.token != undefined && prm.token != '') {
      state.login = prm.login
      state.token = prm.token
      state.adr = prm.adr
      state.org = prm.org
      state.adr_name = prm.adr_name
      state.resource = prm.resource
      state.tpl = prm.tpl
      state.user_fio = prm.user_fio
      //            state.smena = prm.smena;
      localStorage.setItem('AuthLogin', prm.login)
      localStorage.setItem('AuthToken', prm.token)
      localStorage.setItem('Adr', prm.adr)
      localStorage.setItem('Org', prm.org)
      localStorage.setItem('AdrName', prm.adr_name)
      localStorage.setItem('Resource', prm.resource)
      localStorage.setItem('Tpl', prm.tpl)
      localStorage.setItem('User_fio', prm.user_fio)
      axios.defaults.headers.post['token'] = prm.token
      axios.defaults.headers.post['login'] = prm.login
      axios.defaults.headers.post['adr'] = prm.adr

      //        console.log(this.a.app.$store.state.auth.tpl)
      //            var href=$('#bootstrap').attr('href'),
      //            h=href.lastIndexOf("/"),
      //            a=href.substring(0,h);
      //            console.log(a+'/bootstrap'+prm.tpl+'.min.css');
      //            $('#bootstrap').attr('href',a+'/bootstrap'+prm.tpl+'.min.css');
    }
  },
  logOut() {
    localStorage.setItem('AuthLogin', '')
    localStorage.setItem('AuthToken', '')
    localStorage.setItem('Adr', '')
    localStorage.setItem('Org', '')
    localStorage.setItem('AdrName', '')
    localStorage.setItem('Resource', '')
    localStorage.setItem('Tpl', '')
    localStorage.setItem('User_fio', '')
    state.login = ''
    state.token = ''
    state.adr = ''
    state.org = ''
    state.adr_name = ''
    state.tpl = ''
    state.user_fio = ''
    state.resource = []
  },
  setAdr(state, adr) {
    state.adr = adr.id
    state.adr_name = adr.name
    localStorage.setItem('Adr', adr.id)
    localStorage.setItem('AdrName', adr.name)
  },
  setTpl(state, tpl) {
    state.tpl = tpl
    localStorage.setItem('Tpl', tpl)
  },
  setUserfio(state, fio) {
    state.user_fio = fio
    localStorage.setItem('User_fio', fio)
  },
  setSmena(state, smena) {
    state.smena = smena
  },
}
const actions = {
  loadSmena(store) {
    return new Promise(resolve => {
      //            if (state.smena.length == 0) {
      Vue.http.post(store.rootState.apiUrl + 'smena/getSmena/').then(
        response => {
          store.state.smena = response.data
          resolve()
        },
        err => {
          console.log(err)
          resolve()
        },
      )
      //            } else {
      //                resolve();
      //            }
    })
  },
}

//const computed = {
//  getToken () {
//    return this.$store.state.token
//  }
//}

export default {
  state,
  mutations,
  actions,
}
