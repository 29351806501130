<template>
  <v-app>
    <v-main>
      <router-view></router-view>
      <v-snackbar :timeout="-1" :value="true" app top outlined v-show="message" :color="messageCls">
        {{ message }}
      </v-snackbar>
      {{ loadData }}
      <v-dialog v-model="loadData" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            Загрузка данных ...
            <v-progress-linear indeterminate rounded color="white" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
export default {
  computed: {
    loadData: function() {
      return this.$store.state.loadData
    },
    message: function() {
      return this.$store.state.message
    },
    messageCls: function() {
      if (this.$store.state.messageType == 'error') return 'red darken-2'
    },
  },
}
</script>

<style></style>
